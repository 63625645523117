<template>
  <div class="page">
    <!-- <div class="flex"><u class="color-primary flex-right"><a href="http://mallrobot-akc.oss-cn-hangzhou.aliyuncs.com/akc_smdl.mp4">视频教程</a></u></div> -->
    <a href="http://mallrobot-akc.oss-cn-hangzhou.aliyuncs.com/akc_smdl.mp4" class="flex"><img src="../../assets/fixed_btn.png" class="fixed_btn flex-right" /></a>
    <div class="flex column h-center">
      <div class="relative mt-70">
        <img v-if="!robot.robotWxId"
             src="../../assets/robot.png"
             class="avatar">
        <img v-if="robot.robotWxId"
             :src="robot.headImgUrl"
             class="avatar">
        <div v-if="robot.robotWxId && robot.state === 0"
             class="live_out flex h-center">
          已离线
        </div>
      </div>

      <div class="fs-18 mt-2 nickname">{{ robot.nickName ? robot.nickName : '爱跟推' }}</div>
      <div class="color-gray fs-14 text-center">{{userCode}}</div>

    </div>

    <div v-if="!robot.robotWxId">
      <button class="btn mt-8"
              @click="toLogin()">托管微信号</button>

      <div class="mt-30 fs-12">说明：</div>
      <div class="flex v-top color-gray fs-12 mt-1">
        <div>1.</div>
        <div>
          请用另一台设备（或让好友帮忙）拍摄二维码，或者手机截屏保存二维码后将图片发送给其他手机或者电脑；
        </div>
      </div>
      <div class="flex v-top color-gray fs-12 mt-1">
        <div>2.</div>
        <div>打开需要登录的微信，打开「扫一扫」扫描二维码登录；</div>
      </div>
      <div class="flex v-top color-gray fs-12 mt-1">
        <div>3.</div>
        <div>
          扫码成功后，系统等待几秒获取账号信息后会自动刷新，请耐心等待；
        </div>
      </div>
      <div class="flex v-top color-gray fs-12 mt-1">
        <div>4.</div>
        <div>为了避免账号异常，请勿重复扫码。</div>
      </div>
    </div>

    <!-- <div class="flex fs-12 mt-100 h-center"><img src="../../assets/icon_video.png" class="icon_video" /><u><a href="http://mallrobot-akc.oss-cn-hangzhou.aliyuncs.com/akc_smdl.mp4" class="ml-1 color-primary text-decoration">操作视频</a></u></div> -->

    <van-popup v-model:show="show"
               style="width: 75%"
               class="flex column pl-3 pr-3">
      <div class="fs-14 b mt-4">如何让机器人更加稳定，不容易掉线？</div>
      <div class="fs-12 mt-3 color-gray">1.用户不要主动关闭微信登陆（比如关闭网页或者windows登陆）；</div>
      <div class="fs-12 mt-1 color-gray">2.用户不要随意更换登陆设备（比如从原先使用的手机换到另外一个手机上登陆）；</div>
      <div class="fs-12 mt-1 color-gray">3.用户应避免在使用中的同时扫码登陆到其他PC端（比如扫码登陆到其他平板类设备）；</div>
      <div class="fs-12 mt-1 color-gray">4.用户应避免随意频繁的更改微信密码（比如手机端收到官方提醒 您微信存在环境异常，请确认是否本人登陆，为了安全，请修改登陆密码之类的）；</div>
      <div class="fs-12 mt-1 color-gray">5.在初始登陆的24小时内，避免频繁操作，如需发送群消息或者个友信息，间隔时间最好为5-8分钟</div>
      <div class="fs-12 mt-1 color-primary b">重点：商家在进行了以上第2点、第3点后，之后的一个周期（8天）内都有一定几率会被官方踢下线！！！</div>
      <button class="mt-4 mb-4 reflesh b"
              @click="show=false">我知道了</button>
    </van-popup>

    <van-overlay :show="logoutDialog"
                 bind:click="onClickHide">
      <div class="popupRemind">
        <div class="popup_remind_txt">
          <span>微信号注销后，微信相关的设置</span>
          <span class="color-danger">（入群欢迎语、群发、关键词回复、采集对象设置）</span>
          <span>将会清除，确定注销？</span>
        </div>
        <div class="bottom_btn flex">
          <div class="bottom_btn_cancel flex h-center"
               @click="ensureLogout(false)">
            取消
          </div>
          <div class="bottom_btn_ensure flex h-center"
               @click="ensureLogout(true)">
            确定
          </div>
        </div>
      </div>
    </van-overlay>

    <div v-if="robot.robotWxId && robot.state === 0">
      <button class="btn mt-8"
              @click="toLogin('again')">重新登录</button>

      <u class="fs-14 mt-2 color-danger flex h-center"
         @click="logoutWx()">解绑此微信号</u>

      <div class="color-blue flex fs-12 mt-230 h-center"
           @click="show=true">
        如何让您的登录的机器人更加稳定，不容易掉线？
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Toast } from 'vant'
import { getParams } from '@/utils'

export default {
  name: 'Index',
  components: {},
  data () {
    return {
      loading: false,
      robot: {
        state: 0,
        headImgUrl: '../../assets/avatar.png',
        nickName: '爱跟推'
      },
      wxType: 1,
      userCode: '',
      userCodes: '',
      userInfo: {},
      show: false,
      logoutDialog: false,
      // 是否含有端口
      hasGetPort: false,
      portTimer: void 0,
    }
  },
  computed: {},
  async created () {
    const _this = this
    if (_this.$route.query && _this.$route.query.isLogin) {
      _this.getPort()
    } else {
      _this.initData()
    }
  },
  beforeUnmount(){
    clearTimeout(this.portTimer)
  },
  methods: {
    async initData () {
      const _this = this
      _this.clearToken()
      const toast1 = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      })
      await _this.getUserInf()
      await _this.getData()
      if (_this.userCode && _this.userCode != 'xxx') {
        await _this.getPort()
      }
      toast1.clear()
    },
    clearToken () {
      localStorage.removeItem('Token')
    },
    getUserInf () {
	    const params = getParams(location.href)
	    this.userCodes = params.code
    },
    getData () {
      const _this = this
      if (!_this.userCodes || _this.userCodes == 'xxx') {
        _this.$router.replace('/login/404')
      } else {
        // debugger
        if (localStorage.getItem('tokens') && localStorage.getItem('code') && localStorage.getItem('code') == _this.userCodes) {
          _this.userCode = localStorage.getItem('code')
          localStorage.setItem('Token', localStorage.getItem('tokens'))
          // return Promise.resolve()
        }
        return new Promise((resolve, reject) => {
          _this.$post('/User/ShopManagerLogin', { code: _this.userCodes, headImgUrl: _this.userInfo.avatarUrl, nickName: _this.userInfo.nickName }).then((res) => {
            if (res) {
              localStorage.setItem('tokens', res.token)
              localStorage.setItem('Token', res.token)
              localStorage.setItem('code', _this.userCodes);
              const userInfo = { nickName: res.nickName, avatarUrl: '', userSerialNo: res.id, subscribeExpireDate: res.subscribeExpireDate.split(" ")[0],
                // 爱库存商户类型（0店主、1店长）
                merchantType: res.merchantType
              }
              _this.$store.dispatch('setUserInfo', userInfo)
              _this.userInfo = userInfo
              _this.userCode = _this.userInfo.userSerialNo || (localStorage.getItem('UserInfo') && JSON.parse(localStorage.getItem('UserInfo')).userSerialNo)
              localStorage.setItem('code', _this.userCode)
              // console.log(_this.userCode)
            }
            resolve()
          }).catch((e) => {
            if (e === -10001) {
              _this.$router.replace('/login/noRobot')
            }
            reject();
          })
        })
      }
    },
    getPort () {
      const _this = this
      return new Promise((resolve, reject) => {
        _this.$post('/WxRobot/GetPorts', { wxType: _this.wxType })
          .then((res) => {
            // 如果没有拿到数据就返回
            if(!res || res.length === 0){
              // 判断是否查询到端口号
              this.portTimer = setTimeout(() => {
                this.getPort()
              }, 2500)
              return
            }
            clearInterval(this.portTimer)
            this.hasGetPort = true

            _this.robot = res[0]
            if (_this.robot.robotWxId && _this.robot.state !== 0) {
              _this.$router.replace('/index')
            }
            resolve()
          })
          .catch((e) => {
            if (e === -10001) {
              _this.$router.replace('/login/noRobot')
            }
            reject()
          })
      })
    },

    toLogin (value) {
      const _this = this
      // 如果不是重新登录 并且没有拿到端口号
      if(!value && !this.hasGetPort){
        this.$Notify({
          type: 'warning',
          message: '系统正在生成您的专属端口，大约需要5秒左右，请稍后再试~',
          duration: 2000
        })
        return
      }
      _this.$router.push('/login/login')
    },

    async ensureLogout (value) {
      const _this = this
      if (value) {
        const toast1 = Toast.loading({
          message: '注销中...',
          forbidClick: true,
          loadingType: 'spinner'
        })
        await _this.$post('/WxRobot/WxRobotLogout', {
          robotWxId: _this.robot.robotWxId,
          deviceId: _this.robot.deviceId,
          wxType: 0,
          reason: [
            {
              reasonType: 1,
              reasonData: '默认'
            }
          ]
        })
          .then((res) => { toast1.clear() })
        toast1.clear()
        _this.getPort()
      }
      _this.logoutDialog = false
    },

    logoutWx () {
      const _this = this
      _this.logoutDialog = true
    },

    reconvert (str) {
      str = str.replace(/(\\u)(\w{1,4})/gi, function ($0) {
        return (String.fromCharCode(parseInt((escape($0).replace(/(%5Cu)(\w{1,4})/g, "$2")), 16)));
      });
      str = str.replace(/(&#x)(\w{1,4});/gi, function ($0) {
        return String.fromCharCode(parseInt(escape($0).replace(/(%26%23x)(\w{1,4})(%3B)/g, "$2"), 16));
      });
      str = str.replace(/(&#)(\d{1,6});/gi, function ($0) {
        return String.fromCharCode(parseInt(escape($0).replace(/(%26%23)(\d{1,6})(%3B)/g, "$2")));
      });

      return str;
    },

    showModal () {
      const _this = this

    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
